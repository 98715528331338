import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { GoogleMap, DirectionsRenderer } from '@react-google-maps/api';
import '../Styles/CustomerOrderTracking.css';
import { OrderContext } from '../Context/OrderContext';

const CustomerOrderTracking = () => {
  const { orderId } = useContext(OrderContext);

  // State
  const [orderStatus, setOrderStatus] = useState('');
  const [driverName, setDriverName] = useState('');
  const [dispensaryAddress, setDispensaryAddress] = useState('');
  const [customerAddress, setCustomerAddress] = useState('');
  const [items, setItems] = useState([]);         // To store ordered items
  const [totalAmount, setTotalAmount] = useState(0); // To store order total

  const [directions, setDirections] = useState(null);

  // Fetch order data (status, driver, items, addresses, total, etc.)
  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        console.log('Fetching order status for order ID:', orderId);
        const response = await axios.get(
          `https://toke-api-ye64.onrender.com/orders/${orderId}`
        );

        const orderData = response.data;
        console.log('Fetched order data:', orderData);

        // Basic info
        setOrderStatus(orderData.status);
        setDriverName(orderData.driverName);

        // Items & total
        setItems(orderData.items || []);
        setTotalAmount(orderData.totalAmount || 0);

        // Addresses
        // If each item includes 'dispensaryAddress', pick the first item’s address
        // or you can store it separately in the orderData if needed.
        if (orderData.items?.length > 0) {
          setDispensaryAddress(orderData.items[0].dispensaryAddress);
        }
        setCustomerAddress(orderData.address);

      } catch (error) {
        console.error('Error fetching order status:', error);
      }
    };

    if (orderId) {
      fetchOrderStatus();
    }
  }, [orderId]);

  // Fetch map directions once addresses are set
  useEffect(() => {
    const fetchDirections = async () => {
      if (dispensaryAddress && customerAddress) {
        console.log(
          'Fetching directions from',
          dispensaryAddress,
          'to',
          customerAddress
        );

        const directionsService = new window.google.maps.DirectionsService();
        directionsService.route(
          {
            origin: dispensaryAddress,
            destination: customerAddress,
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (result, status) => {
            if (status === window.google.maps.DirectionsStatus.OK) {
              console.log('Directions result:', result);
              setDirections(result);
            } else {
              console.error(`Error fetching directions: ${status}`);
            }
          }
        );
      }
    };

    fetchDirections();
  }, [dispensaryAddress, customerAddress]);

  return (
    <div className="track-order-container">
      <h1 className='track-order-header'>Track Order</h1>

      {/* Order Status & Driver */}
      <p><strong>Order Status:</strong> {orderStatus}</p>
      {driverName && <p><strong>Driver:</strong> {driverName}</p>}

      {/* Addresses */}
      <p><strong>Delivering to:</strong> {customerAddress}</p>

      {/* Items */}
      <div>
        <h2>Items Ordered</h2>
        {items.length > 0 ? (
          items.map((item) => (
            <div key={item._id} className="ordered-item">
              <p>
                <strong>{item.name}</strong>  
                <br />
                ${item.price} x {item.quantity}
                <br />
                {item.dispensaryName && (
                  <>
                    <span className="dispensary-tag">
                      From: {item.dispensaryName}
                    </span>
                  </>
                )}
              </p>
              {/* You can also display item.imageUrl if desired */}
            </div>
          ))
        ) : (
          <p>No items found in this order.</p>
        )}
      </div>

      {/* Order Total */}
      <p><strong>Order Total:</strong> ${totalAmount.toFixed(2)}</p>

      {/* Google Map */}
      {customerAddress && (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: 33.6846, lng: -117.8265 }} // Example center
          zoom={12}
        >
          {directions && <DirectionsRenderer directions={directions} />}
        </GoogleMap>
      )}
    </div>
  );
};

export default CustomerOrderTracking;
