import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import NumericStepper from '../Components/numericStepper'; // Adjust path as needed
import { useCart } from '../Context/CartContext'; // Adjust path as needed
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import '../Styles/Product.css'; // Make sure this path is correct
import { useNavigate } from 'react-router-dom';
import GoToTop from '../Components/GoToTop';

const Product = () => {
  const location = useLocation();
  const product = location.state.product;
  const [quantity, setQuantity] = useState(1);
  const { addToCart, showItemAddedPopup } = useCart();
  const navigate = useNavigate();

  const handlePopUp = () => {
    navigate('/cart');
  };

  const handleAddToCart = () => {
    const productToAdd = { ...product, quantity };
    console.log("Adding to cart:", productToAdd.id);
    addToCart(productToAdd);
  };

  return (
    <div className="container">
      {showItemAddedPopup && (
        <div
          aria-live="polite"
          style={{
            fontWeight: 'bold',
            fontSize: '13px',
            position: 'fixed',
            top: '5%',
            right: '5%',
            backgroundColor: 'white',
            color: '#101522',
            padding: '15px',
            borderRadius: '0px',
            boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            zIndex: '9999999',
            opacity: '1',
            transform: 'translateY(0)',
            width: '300px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '0 auto',
            transition: 'opacity 0.2s ease, transform 0.2s ease'
          }}
        >
          Added to cart!
          <p>
            <button
              style={{
                color: 'white',
                backgroundColor: '#101522',
                borderRadius: '0px',
                padding: '10px 20px',
                transition: 'background-color 0.3s ease',
                border: 'none'
              }}
              onClick={handlePopUp}
              onMouseOver={(e) => (e.target.style.backgroundColor = 'gray')} // Hover effect
              onMouseOut={(e) => (e.target.style.backgroundColor = '#101522')} // Reset on mouse out
            >
              View Cart
            </button>
          </p>
        </div>
      )}

      <div className="imageContainer">
        <img
          src={product.imageUrl}
          alt={product.name}
          className="productImage"
        />
      </div>

      <div className="details">
        <h2 className="productName">{product.name}</h2>

        {/*  Render product.description if available */}
        {product.description && (
          <p className="productDescription">{product.description}</p>
        )}
        {/* If you want a fallback message when no description is available, uncomment below:
        {!product.description && (
          <p className="productDescription">No description available.</p>
        )} */}

        <p className="thcPercentage">${product.price}</p>
        <p className="thcPercentage">{product.thcPercentage}% THC</p>
        <p className="dispensaryName">{product.dispensaryName}</p>

        <NumericStepper
          quantity={quantity}
          setQuantity={setQuantity}
          className="stepper"
        />

        <button onClick={handleAddToCart} className="addToCartButton">
          <FontAwesomeIcon icon={faShoppingCart} className="cartIcon" />
          <span className="buttonText">Add to Cart</span>
        </button>
      </div>

      <GoToTop />
    </div>
  );
};

export default Product;
